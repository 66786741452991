import * as React from 'react';
import {useSelector} from "react-redux";

type LteIPState = {
    lteIP: string | undefined;
};

export const LteIPComponent = () => {
    const lteIP = useSelector((state: LteIPState) => state.lteIP)
    return <React.Fragment>
        {lteIP}
    </React.Fragment>
}