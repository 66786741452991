import React from "react";
import {Helmet} from 'react-helmet';
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';
import MD5 from "crypto-js/md5";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AdminLayout from "layouts/Admin.js";
import store from '../store';
import {lang} from "./Dashboard.js";

const useStyles = makeStyles(styles);

export default function ChangepwPageAgain() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [pw_first, setPwFirst] = React.useState("");
  const [pw_second, setPwSecond] = React.useState("");
  const [pw_current, setPwCurrent] = React.useState("");
  var login_username;
  var login_password;
  var login_fir;

  function handleSubmit() {
    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("/current_user", requestOptions)
    .then(response => { 
      return response.json()
    })
    .then(data => {
      Object.keys(data).forEach(function(key) {
        if(key == "username")
          login_username = data[key]
        else if(key == "password")
          login_password = data[key]
        else if(key == "firstin")
          login_fir = data[key]
      })
      if ((pw_first == pw_second) && (MD5(pw_current) == login_password)) {
        changePassword()
      } 
      else {
        alert(lang.id_updatepwerr)
      }
    })
  }

  function changePassword() {
    var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("newpw", pw_first);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("/change_pw", requestOptions)
        .then(response => {
          response.text()
          if(response.status == 200) {
            renderPage()
            alert(lang.id_updatepwsuccess)
            window.location="/"
          }
        })
        .catch(e => {
          alert("Error:"+e)
        })
  }

  function renderPage() {
    ReactDOM.render(
      <Provider store={store}>
          <BrowserRouter>
              <Switch>
                  <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                  <Redirect from="/" to="/admin/dashboard"/>
              </Switch>
          </BrowserRouter>,
      </Provider>,
      document.getElementById("root")
    );
  }

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridItem xs={12} sm={6} md={4}>
        <Card login className={classes[cardAnimaton]}>
          <CardBody>
            <CustomInput
            labelText={lang.id_currentpw}
            id="currentpw"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                </InputAdornment>
              ),
              onChange: (e) => {setPwCurrent(e.target.value)},
              type: "password",
              autoComplete: "off",
            }}
            />
            <CustomInput
              labelText={lang.id_newpw}
              id="password1"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  </InputAdornment>
                ),
                onChange: (e) => {setPwFirst(e.target.value)},
                type: "password",
                autoComplete: "off",
              }}
            />
            {/* <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            /> */}
            <CustomInput
              labelText={lang.id_renewpw}
              id="password2"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  </InputAdornment>
                ),
                onChange: (e) => {setPwSecond(e.target.value)},
                type: "password",
                autoComplete: "off",
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="primary" size="lg" block onClick={() => handleSubmit()}>
             {lang.id_changpw_update}
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </div>
  );
}