import React from "react";
import {useDispatch} from 'react-redux'
import {
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@mui/material/Switch';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import store from '../store';
import {lang} from "./Dashboard.js";

const useStyles = makeStyles(styles);

export default function ChangepwPageAgain() {
  const dispatch = useDispatch();
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [showPeocess, setShowProcess] = React.useState(false);
  const [requiredState, setrequiredState] = React.useState("");
  const [checkDataRoaming, setCheckDataRoaming] = React.useState(false);
  const [checkAutoSetup, setCheckAutoSetup] = React.useState(true);
  const [simpleType, setSelectType] = React.useState("");
  const [simpleOperator, setSelectOperator] = React.useState("");
  const [simpleStartBilling, setSelectStartBilling] = React.useState("");

  // function that verifies if value contains only numbers
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleType = (event) => {
    setSelectType(event.target.value);
  };

  const handleOperator = (event) => {
    setSelectOperator(event.target.value);
  };

  const handleStartBilling = (event) => {
    setSelectStartBilling(event.target.value);
  };

  function handleSubmit() {
    var text_sim_pin = document.getElementById("id_text_sim_pin").value;
    var text_name = document.getElementById("id_text_name").value;
    var text_username = document.getElementById("id_text_username").value;
    var text_password = document.getElementById("id_text_password").value;

    if (text_name.length <= 0) {
      alert(lang.id_updateLTEerr);
    }
    if (text_sim_pin.length > 0) {
      if(text_sim_pin.length < 4) {
        alert(lang.id_updateLTEerr);
      }
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("lte_sim_pin", text_sim_pin);
    urlencoded.append("lte_name", text_name);
    urlencoded.append("lte_username", text_username);
    urlencoded.append("lte_password", text_password);
    setShowUpdate(false)
    setShowProcess(true)

    var requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("/command/set_lte", requestOptions)
    .then(response => {
      response.text()
      if(response.status == 200) { 
        setShowProcess(false)
      } else {
        window.location="/";
      }
    })
    .catch(e => {
      setShowProcess(false)
      alert("Error:"+e)
    })
  }
  
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <form>
        {/* <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_dataRoaming}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkDataRoaming}
                  onChange={(event) =>
                    setCheckDataRoaming(event.target.checked)
                  }
                  value="checkDataRoaming"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
            />
            <FormLabel id="id_label_data" className={classes.labelHorizontal}>
              {checkDataRoaming ? "ON" : "OFF"}
            </FormLabel>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_type}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel id="demo-simple-select-helper-label">{lang.id_type}</InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={simpleType}
                onChange={handleType}
                inputProps={{
                  name: "simpleType",
                  id: "type-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="2"
                >
                  {lang.id_typeSelection_3g}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="3"
                >
                  {lang.id_typeSelection_4g}
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer> */}

        <GridContainer>
          <GridItem xs={20} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_simPin}
            </FormLabel>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <TextField
              label={lang.id_simPin}
              id="id_text_sim_pin"
              margin="dense"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 8 }}
              fullWidth
            />
          </GridItem>
        </GridItem>

        {/* <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_autoSetup}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkAutoSetup}
                  onChange={(event) =>
                    setCheckAutoSetup(event.target.checked)
                  }
                  value="checkAutoSetup"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
            />
            <FormLabel id="id_label_auto" className={classes.labelHorizontal}>
              {checkAutoSetup ? "ON" : "OFF"}
            </FormLabel>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_operator}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={simpleOperator}
                onChange={handleOperator}
                inputProps={{
                  name: "simpleOperator",
                  id: "id_select_operator",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="2"
                >
                  {lang.id_typeSelection_3g}
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer> */}
        <br/>
        <GridContainer>
          <GridItem xs={20} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_apnSettings}
            </FormLabel>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_name}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <TextField
              label={lang.id_name}
              id="id_text_name"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
          </GridItem>
            {/* <GridItem xs={12} sm={7} md={5} lg={5}>
              <CustomInput
                success={requiredState === "success"}
                error={requiredState === "error"}
                id="required"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    if (verifyLength(event.target.value, 0)) {
                      setrequiredState("success");
                    } else {
                      setrequiredState("error");
                    }
                    setrequired(event.target.value);
                  },
                  type: "text",
                  endAdornment:
                    requiredState === "error" ? (
                      <InputAdornment position="end">
                        <Close className={classes.danger} />
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </GridItem> */}
            
          <GridItem xs={12}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_username}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={7} md={5} lg={5}>
            <TextField
              label={lang.id_username}
              id="id_text_username"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
          </GridItem>

          <GridItem xs={12}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_password}
            </FormLabel>
          </GridItem>

          <GridItem xs={12} sm={7} md={5} lg={5}>
            <TextField
              label={lang.id_password}
              id="id_text_password"
              type="password"
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
            />
          </GridItem>
            {/* <GridItem xs={12} sm={7} md={5} lg={5}>
              <CustomInput
                success={requiredState === "success"}
                error={requiredState === "error"}
                id="required"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    if (verifyLength(event.target.value, 0)) {
                      setrequiredState("success");
                    } else {
                      setrequiredState("error");
                    }
                    setrequired(event.target.value);
                  },
                  type: "text",
                  endAdornment:
                    requiredState === "error" ? (
                      <InputAdornment position="end">
                        <Close className={classes.danger} />
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </GridItem> */}
        </GridItem>

        {/* <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {lang.id_dataUsageSettings}
            </FormLabel>
          </GridItem>
        </GridContainer>
        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {lang.id_startBillingOn}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={7} md={5} lg={5}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={simpleStartBilling}
                  onChange={handleStartBilling}
                  inputProps={{
                    name: "simpleStartBilling",
                    id: "id_select_start_billing",
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="2"
                  >
                    5th
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {lang.id_dataUsageLimit}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={7} md={5} lg={5}>
              <TextField
                label={lang.id_dataUsageLimit}
                id="id_text_dataUsageLimit"
                type="number"
                margin="dense"
                variant="outlined"
                size="small"
                InputProps={{ endAdornment: <InputAdornment position="end">GB</InputAdornment>, }}
                fullWidth
              />
            </GridItem>
          </GridContainer>
        </GridItem> */}
        <br/>
        <Button
          className="btn-fill btn-wd"
          variant="info"
          onClick={() => setShowUpdate(true)}
        >
          {lang.id_updatebtn}
        </Button>
      </form>
      <Modal
          className="modal-mini modal-primary"
          show={showUpdate}
          onHide={() => setShowUpdate(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_update_dialogue_lte}</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowUpdate(false)}
            >
              {lang.id_back}
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => handleSubmit()}
            >
              {lang.id_updatebtn_ok}
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-mini modal-primary"
          show={showPeocess}
          onHide={() => setShowProcess(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_update_process}</p>
          </Modal.Body>
        </Modal>
    </div>
  );
}