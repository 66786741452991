import * as React from 'react';
import {useSelector} from "react-redux";

type onBoardKeyState = {
    onboardKey: string | undefined;
};

export const OnboardKeyComponent = () => {
    const onboardKey = useSelector((state: onBoardKeyState) => state.onboardKey)
    return <React.Fragment>
        {onboardKey}
    </React.Fragment>
}