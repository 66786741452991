import * as React from 'react';
import {useSelector} from "react-redux";

type RegionInfo = {
    region: string | undefined;
};

export const RegionComponent = () => {
    const region = useSelector((state: RegionInfo) => state.region)
    return <React.Fragment>
        {region}
    </React.Fragment>
}