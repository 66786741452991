import * as React from 'react';
import {useSelector} from "react-redux";

type CpuState = {
    cpuUsage: number | undefined;
    cpuTemp: number | undefined;
};

export const CpuUsageComponent = () => {
    const cpuUsage = useSelector((state: CpuState) => state.cpuUsage)
    return <React.Fragment>
        {cpuUsage}%
    </React.Fragment>
}

export const CpuTempComponent = () => {
    const cpuTemp = useSelector((state: CpuState) => state.cpuTemp)
    return <React.Fragment>
        {cpuTemp}℃
    </React.Fragment>
}