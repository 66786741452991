import * as React from 'react';
import {useSelector} from "react-redux";

type LanIPState = {
    lanIP: string | undefined;
};

export const LanIPComponent = () => {
    const lanIP = useSelector((state: LanIPState) => state.lanIP)
    return <React.Fragment>
        {lanIP}
    </React.Fragment>
}