import * as React from 'react';
import {useSelector} from "react-redux";

type LanState = {
    lanMac: string | undefined;
};

export const LanMacComponent = () => {
    const lanMac = useSelector((state: LanState) => state.lanMac)
    return <React.Fragment>
        {lanMac}
    </React.Fragment>
}