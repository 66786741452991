import * as React from 'react';
import {useSelector} from "react-redux";

type SNState = {
    snCode: string | undefined;
};

export const SNComponent = () => {
    const snCode = useSelector((state: SNState) => state.snCode)
    return <React.Fragment>
        {snCode}
    </React.Fragment>
}