import * as React from 'react';
import {useSelector} from "react-redux";

type ModelNameState = {
    modelName: number | undefined;
};

export const ModelNameComponent = () => {
    const modelName = useSelector((state: ModelNameState) => state.modelName)
    return <React.Fragment>
        {modelName}
    </React.Fragment>
}