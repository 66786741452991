/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Reboot from "views/Reboot.js";
import ChangepwPageAgain from "views/ChangepwAgain.js";
import Logout from "views/Logout.js";
import ForgetWifi from "views/ForgetWifi.js";
import ConnectionPriority from "views/ConnectionPriority.js";
import LTEManager from "views/LTEManager.js";
import en from "assets/lang/en.json";
var lang = en;

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: lang.id_overview,
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
     path: "/changepassword",
     name: lang.id_changepw,
     icon: "nc-icon nc-settings-90",
     component: ChangepwPageAgain,
     layout: "/admin",
   },
   {
      path: "/connectionpriority",
      name: lang.id_connection_priority,
      icon: "nc-icon nc-chart-bar-32",
      component: ConnectionPriority,
      layout: "/admin",
   },
   {
      path: "/letmanager",
      name: lang.id_lteManager,
      icon: "nc-icon nc-chart-bar-32",
      component: LTEManager,
      layout: "/admin",
   },
   {
      path: "/forgetwifi",
      name: lang.id_forget_wifi,
      icon: "nc-icon nc-scissors",
      component: ForgetWifi,
      layout: "/admin",
    },
   {
      path: "/reboot",
      name: lang.id_reboot,
      icon: "nc-icon nc-button-power",
      component: Reboot,
      layout: "/admin",
    },
   {
      path: "/logout",
      name: lang.id_logout,
      icon: "nc-icon nc-lock-circle-open",
      component: Logout,
      layout: "/admin",
    },
];

export default dashboardRoutes;
