import React from "react";
import {Helmet} from 'react-helmet';
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AdminLayout from "layouts/Admin.js";
import ChangepwPage from "./Changepw.js"
import store from '../store';
import {lang} from "./Dashboard.js";

const useStyles = makeStyles(styles);
var login_first;

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  function validateForm() {
    return username.length > 0;
  }

  function handleSubmit() {
    var loginSuccess;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", username);
    urlencoded.append("password", password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };  

    fetch('/login_odd',requestOptions)
    .then(response => {
      if(response.status == 200) {
        loginSuccess = true
        return response.json()
      }
      else {
        alert(lang.id_loginerr);
      }
    })
    .then(data => {
      Object.keys(data).forEach(function(key) {
        if(key == "firstin")
          login_first = data[key]
      })
      if(loginSuccess) {
        store.dispatch({ type: 'FETCH_SYSTEM_ACTION' })
        renderPage()
      }
    })
    .catch(e => {
      console.log("Error:"+e)
    })
  }

  function renderPage() {
    ReactDOM.render(
      <Provider store={store}>
          <BrowserRouter>
              <Switch>
                  {login_first
                      ? <Route path="/admin" render={(props) => <ChangepwPage {...props} />}/>
                      : <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                  }
                  {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />}/> */}
                  <Redirect from="/" to="/admin/dashboard"/>
              </Switch>
          </BrowserRouter>,
      </Provider>,
      document.getElementById("root")
    );
  }

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Helmet>
        <style>{'body { background-color: #60C2D3; }'}</style>
      </Helmet>
      <GridContainer justify="center" style={{
          paddingTop: '150px',
        }} >
        
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>{lang.id_ondevicedb}</h4>
                {/* <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div> */}
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText={lang.id_login_username}
                  id="firstname"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                      </InputAdornment>
                    ),
                    onChange: (e) => {setUsername(e.target.value)},
                  }}
                />
                {/* <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <CustomInput
                  labelText={lang.id_login_passwd}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                      </InputAdornment>
                    ),
                    onChange: (e) => {setPassword(e.target.value)},
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" size="lg" block disabled={!validateForm()} onClick={() => handleSubmit()}>
                  {lang.id_loginbtn}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}