import * as React from 'react';
import {useSelector} from "react-redux";

type StorageState = {
    storageUsage: number | undefined;
};

export const StorageComponent = () => {
    const storageUsage = useSelector((state: StorageState) => state.storageUsage)
    return <React.Fragment>
        {storageUsage}%
    </React.Fragment>
}