import {CpuUsageComponent} from "../CPU";
import {CpuTempComponent} from "../CPU";
import {MemoryComponent} from "../Memory";
import {StorageComponent} from "../Storage";
import {AnimalComponent} from "../AnimalName";
import {ModelNameComponent} from "../ModelName";
import {OnboardKeyComponent} from "../OnboardKey";
import {RegionComponent} from "../Region";
import {UpTimeComponent} from "../MinerUpTime";
import {FirmwareComponent} from "../FirmwareVersion";
import {SNComponent} from "../SnCode";
import {LanMacComponent} from "../LanMac";
import {LanIPComponent} from "../LanIP";
import {WlanComponent} from "../WlanMac";
import {WlanIPComponent} from "../WlanIP";
import {LteIPComponent} from "../LTEIP";
import {MinerHeightComponent} from "../MinerHeight";
import { MinerVersionComponent } from "../MinerVersion";
import {BlockHeightComponent} from "../BlockHeight";
import {BlockchainconnComponent} from "../BlockchainConn";
import en from "../assets/lang/en.json";
import {re_model} from "../index.js"
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { mode } from "crypto-js";
import { func } from "prop-types";

var lang = en;
function switch_id_heliumminerversion() {
  if(re_model() == "LD-500") {
    return lang.id_heliumgatewayversion
  } else {
    return lang.id_heliumminerversion
  }
}

function Dashboard() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-puzzle-10 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_cpuusage}</p>
                        <Card.Title as="h5"><CpuUsageComponent /></Card.Title>
                        <Card.Title as="h4"><CpuTempComponent /></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-layers-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_memory}</p>
                      <Card.Title as="h4"><MemoryComponent /></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-36 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_storage}</p>
                      <Card.Title as="h4"><StorageComponent /></Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bold text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_blockchainheight}</p>
                      <BlockHeightComponent/>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bold text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_minerheight}</p>
                      <MinerHeightComponent />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-satisfied text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{lang.id_connectedblockchain}</p>
                      <BlockchainconnComponent/>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">{lang.id_systeminfo}</Card.Title>
              <p className="card-category">
                {lang.id_gatewayreport}
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table-hover table-striped">
                <tbody>
                  <tr>
                    <td>{lang.id_modelname}</td>
                    <td><ModelNameComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_sn}</td>
                    <td><SNComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_firmwareversion}</td>
                    <td><FirmwareComponent /></td>
                  </tr>
                  <tr>
                    <td>{switch_id_heliumminerversion()}</td>
                    <td><MinerVersionComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_aninalname}</td>
                    <td><AnimalComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_minerpublickey}</td>
                    <td><OnboardKeyComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_minerregion}</td>
                    <td><RegionComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_ethmac}</td>
                    <td><LanMacComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_ethip}</td>
                    <td><LanIPComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_wlanmac}</td>
                    <td><WlanComponent /></td>
                  </tr>
                  <tr>
                    <td>{lang.id_wlanip}</td>
                    <td><WlanIPComponent /></td>
                  </tr>
                  {re_model() == "LD-550"
                  ?<tr>
                  <td>{lang.id_lteip}</td>
                  <td><LteIPComponent /></td>
                  </tr>
                  :""
                  }
                  <tr>
                  <td>{lang.id_systemuptime}</td>
                  <td><UpTimeComponent /></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
      </Container>
    </>
  );
}

export default Dashboard;
export {lang}