import * as React from 'react';
import {useSelector} from "react-redux";

type MinerVersion = {
    minerVersion: string | undefined;
};

export const MinerVersionComponent = () => {
    const minerVersion = useSelector((state: MinerVersion) => state.minerVersion)
    return <React.Fragment>
        {minerVersion}
    </React.Fragment>
}