/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import store from './store';

import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import LoginLayout from "views/Login.js";

var loginState, deviceModel, oddVersion;

function LoginState()
{
    fetch('/login_state', {method: "GET"})
    .then(response => {
        response.text()
        if(response.status == 200) {
            loginState = true
        }
        else {
            loginState = false
        }

        ReactDOM.render(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {loginState
                            ? <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                            : <Route path="/admin" render={(props) => <LoginLayout {...props} />}/>
                        }
                        <Redirect from="/" to="/admin/dashboard"/>
                    </Switch>
                </BrowserRouter>,
            </Provider>,
            document.getElementById("root")
        );
    })
    // .then(data => {
    //  alert("data:"+data)
    // })
    .catch(e => {
      alert("Error:"+e)
    })
}

function get_info() {
    fetch('/get_model', { method: 'POST', redirect: 'follow'})
        .then(response => response.json())
        .then(data => {
            Object.keys(data).forEach(function(key) {
                if(key == "model")
                deviceModel = data[key]
                LoginState()
            })
        })
        .catch(error => console.log('error', error));
    
    fetch("/get_odd_version", { method: 'GET', redirect: 'follow'})
        .then(response => response.json())
        .then(data => {
            Object.keys(data).forEach(function(key) {
                if(key == "version")
                oddVersion = data[key]
            })
        })
        .catch(error => console.log('error', error)); 
}

function re_model() {
    return deviceModel;
}

function re_version() {
    return oddVersion;
}

get_info()

export {re_model, re_version};
