import * as React from 'react';
import {useSelector} from "react-redux";

type MemoryState = {
    memoryUsage: number | undefined;
};

export const MemoryComponent = () => {
    const memoryUsage = useSelector((state: MemoryState) => state.memoryUsage)
    return <React.Fragment>
        {memoryUsage}%
    </React.Fragment>
}