import * as React from 'react';
import {useSelector} from "react-redux";

type WlanIPState = {
    wlanIP: string | undefined;
};

export const WlanIPComponent = () => {
    const wlanIP = useSelector((state: WlanIPState) => state.wlanIP)
    return <React.Fragment>
        {wlanIP}
    </React.Fragment>
}