import store from "../store";

const axios = require('axios').default;

const initState = {
    animalName: "-",
    onboardKey: "-",
    region: "-",
    modelName: "-",
    cpuUsage: "-",
    cpuTemp: "-",
    memoryUsage: "-",
    storageUsage: "-",
    uptime: "-",
    firmwareVersion: "-",
    snCode: "-",
    lanMac: "-",
    lanIP: "-",
    wlanMac: "-",
    wlanIP: "-",
    lteIP: "-",
    minerHeight: "-",
    minerVersion: "-",
    blockHeight: "-",
    blockChainAct: "-",
};

const oddReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_SYSTEM_ACTION':
            fetchSystemData();
            return state;
        case 'UPDATE_SYSTEM_ACTION':
            return {
                ...state,
                ...action.system
            }
        case 'REBOOT_DEVICE_ACTION':
            fetchReboot();
            return;
        default:
            return state;
    }
};

async function fetchSystemData() {
    const res = await axios.get("/info/system");
    const systemInfo = res.data
    store.dispatch({
        type: 'UPDATE_SYSTEM_ACTION',
        system: {
            cpuUsage: systemInfo.cpu_usage.toFixed(2),
            cpuTemp: systemInfo.cpu_temp.toFixed(1),
            memoryUsage: systemInfo.memory_usage.toFixed(2),
            storageUsage: systemInfo.storage_usage.toFixed(2),
            animalName: systemInfo.animal_name,
            onboardKey: systemInfo.onboard_key,
            region: systemInfo.region,
            modelName: systemInfo.model_name,
            uptime: systemInfo.uptime,
            firmwareVersion: systemInfo.firmware,
            snCode: systemInfo.sn,
            lanMac: systemInfo.lan,
            lanIP: systemInfo.lan_IP,
            wlanMac: systemInfo.wlan,
            wlanIP: systemInfo.wlan_IP,
            lteIP: systemInfo.lte_IP,
            minerHeight: systemInfo.miner_height,
            minerVersion: systemInfo.miner_version,
            blockHeight: systemInfo.block_height,
            blockChainAct: systemInfo.blockchain_act
        }
    })
}

async function fetchReboot() {
    const res = await axios.get("/command/reboot").then(resp => {}).catch(error => {
        window.location="/";
    });
    const reboot = res.data
    reboot.rebootD
}

export default oddReducer;
