import * as React from 'react';
import {useSelector} from "react-redux";

type AnimalState = {
    animalName: string | undefined;
};

export const AnimalComponent = () => {
    const animalName = useSelector((state: AnimalState) => state.animalName)
    return <React.Fragment>
        {animalName}
    </React.Fragment>
}