import * as React from 'react';
import {useSelector} from "react-redux";

type MinerUpTime = {
    uptime: string | undefined;
};

export const UpTimeComponent = () => {
    const uptime = useSelector((state: MinerUpTime) => state.uptime)
    return <React.Fragment>
        {uptime}
    </React.Fragment>
}