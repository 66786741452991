import * as React from 'react';
import {useSelector} from "react-redux";

type WlanState = {
    wlanMac: string | undefined;
};

export const WlanComponent = () => {
    const wlanMac = useSelector((state: WlanState) => state.wlanMac)
    return <React.Fragment>
        {wlanMac}
    </React.Fragment>
}