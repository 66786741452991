import React from "react";
import { useDispatch } from 'react-redux'
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {lang} from "./Dashboard.js";
import {re_model} from "../index.js"
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";


async function _getNetworkSet() {
  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };
  
  return fetch("/command/get_network_setting", requestOptions)
    .then((response) => {
      if(response.status==200) {
        return response.json()
      }
      else {
        window.location="/"
      }})
    .catch(error => {console.log('error:', error)}); 
}
async function getNetworkSet(){
  try {
    const value = await _getNetworkSet();
    Object.keys(value).forEach(function(key) {
      if(key == "net_name_str") {
        var re_net = value[key]
        document.querySelector('#'+re_net).checked = true;
      }
    })
  } catch (err) {
    console.log('Oops, there was an error :(  '+err);
  }
}

export default function ConnectionPriority() {
  const [network, _Network] = React.useState("");
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [showPeocess, setShowProcess] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
	  getNetworkSet();
  }, [])

  function onChangeValue(event) {
    _Network(event.target.value);
  }

  function setNetwork() {
    var urlencoded = new URLSearchParams();
    urlencoded.append("model", re_model());
    urlencoded.append("set_net_priority", network);
    setShowUpdate(false);
    setShowProcess(true);

    var requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("/command/set_network", requestOptions)
      .then(response => {
        response.text()
        if(response.status == 200) { 
          setShowProcess(false);
        } else {
          window.location="/";
        }
      })
      .catch(e => {
        setShowProcess(false)
        alert("Error:"+e)
      })
  }

  function if550() {
     if (re_model() == "LD-550") {
      return true
     } else {
      return false
     }
  }

  return (
    <>
      <Container fluid>
        {if550()
        ? <div onChange={onChangeValue}>
            <input type="radio" id="lan-wifi-lte" value="lan-wifi-lte" name="gender" checked={network === "lan-wifi-lte"} /> {lang.id_ethernet+" > "+lang.id_wifi+" > "+lang.id_lte} <br /><br />
            <input type="radio" id="lan-lte-wifi" value="lan-lte-wifi" name="gender" checked={network === "lan-lte-wifi"}/> {lang.id_ethernet+" > "+lang.id_lte+" > "+lang.id_wifi} <br /><br />
            <input type="radio" id="wifi-lan-lte" value="wifi-lan-lte" name="gender" checked={network === "wifi-lan-lte"}/> {lang.id_wifi+" > "+lang.id_ethernet+" > "+lang.id_lte} <br /><br />
            <input type="radio" id="wifi-lte-lan" value="wifi-lte-lan" name="gender" checked={network === "wifi-lte-lan"}/> {lang.id_wifi+" > "+lang.id_lte+" > "+lang.id_ethernet} <br /><br />
            <input type="radio" id="lte-lan-wifi" value="lte-lan-wifi" name="gender" checked={network === "lte-lan-wifi"}/> {lang.id_lte+" > "+lang.id_ethernet+" > "+lang.id_wifi} <br /><br />
            <input type="radio" id="lte-wifi-lan" value="lte-wifi-lan" name="gender" checked={network === "lte-wifi-lan"}/> {lang.id_lte+" > "+lang.id_wifi+" > "+lang.id_ethernet} <br /><br />
          </div>
        : <div onChange={onChangeValue}>
            <input type="radio" id="lan-wifi" value="lan-wifi" name="gender" checked={network === "lan-wifi"} /> {lang.id_ethernet+" > "+lang.id_wifi} <br /><br />
            <input type="radio" id="wifi-lan" value="wifi-lan" name="gender" checked={network === "wifi-lan"}/> {lang.id_wifi+" > "+lang.id_ethernet} <br /><br />
          </div>
        }
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Button
              className="btn-fill btn-wd"
              variant="info"
              onClick={() => setShowUpdate(true)}
            >
              {lang.id_updatebtn}
            </Button>
          </Col>
        </Row>
        <Modal
          className="modal-mini modal-primary"
          show={showUpdate}
          onHide={() => setShowUpdate(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_update_dialogue}</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowUpdate(false)}
            >
              {lang.id_back}
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setNetwork()}
            >
              {lang.id_updatebtn_ok}
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-mini modal-primary"
          show={showPeocess}
          onHide={() => setShowProcess(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_update_process}</p>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}