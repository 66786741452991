import React from "react";
import { useDispatch } from 'react-redux'
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import {lang} from "./Dashboard.js";
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Reboot() {
  const [showModal, setShowModal] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const dispatch = useDispatch();

  function rebootFunc() {
    setShowModal(false);
    dispatch({ type: 'REBOOT_DEVICE_ACTION'})
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Button
              className="btn-fill btn-wd"
              variant="info"
              onClick={() => setShowModal(true)}
            >
              {lang.id_rebootbtn}
            </Button>
          </Col>
        </Row>
        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_reboot_dialog}</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowModal(false)}
            >
              {lang.id_back}
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => rebootFunc()}
            >
              {lang.id_rebootbtn_ok}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
      </Container>
    </>
  ); 
}

export default Reboot;
