import * as React from 'react';
import {useSelector} from "react-redux";

type FirmwareState = {
    firmwareVersion: string | undefined;
};

export const FirmwareComponent = () => {
    const firmwareVersion = useSelector((state: FirmwareState) => state.firmwareVersion)
    return <React.Fragment>
        {firmwareVersion}
    </React.Fragment>
}