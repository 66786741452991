import React from "react";
import { useDispatch } from 'react-redux'
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import {lang} from "./Dashboard.js";
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function ForgetWifi() {
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [showPeocess, setShowProcess] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const dispatch = useDispatch();

  function forgetwifiFunc() {
    setShowUpdate(false);
    setShowProcess(true);
    fetch('/command/forgetwifi', {method: "GET"})
    .then(response => {
      response.text()
      if(response.status == 200) {
        setShowProcess(false);
      } else {
        window.location="/";
      }
    })
    .catch(e => {
      setShowProcess(false)
      alert("Error:"+e)
    })
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Button
              className="btn-fill btn-wd"
              variant="info"
              onClick={() => setShowUpdate(true)}
            >
              {lang.id_forgetwifibtn}
            </Button>
          </Col>
        </Row>
        {/* Mini Modal */}
        <Modal
          className="modal-mini modal-primary"
          show={showUpdate}
          onHide={() => setShowUpdate(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_forgetwifi_dialog}</p>
          </Modal.Body>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowUpdate(false)}
            >
              {lang.id_back}
            </Button>
            <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => forgetwifiFunc()}
            >
              {lang.id_forgetwifi_ok}
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-mini modal-primary"
          show={showPeocess}
          onHide={() => setShowProcess(false)}
        >
          <Modal.Header className="justify-content-center">
            <div className="modal-profile">
              <i className="nc-icon nc-bulb-63"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>{lang.id_update_process}</p>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  ); 
}

export default ForgetWifi;
